<template>
  <div class="systemManage-create create-store">
    <el-dialog 
      v-model="dialogVisible" 
      :title="formData.storeId ? $t('stores.Store Details') : $t('stores.Create Store')" 
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="dialog-common-container">
        <el-scrollbar style="height:100%">
          <div class="form-box">
            <el-form 
              label-width="160px"
              style="width: 100%"
              :model="formData"
              ref="form" 
              :rules="rules"
              size="small"
            >
              <div class="section-tit"><div class="flag"></div>{{$t('stores.Basic Information')}}</div>
              <el-form-item :label="$t('stores.Superior Store')" >
                <el-input v-model="parentStoreName" disabled/>
              </el-form-item>
              <el-form-item :label="$t('stores.Status')" prop="customerType">
                <el-select v-model="formData.storeState" placeholder="" >
                  <el-option
                    v-for="item in statusList"
                    :key="item.codeId"
                    :label="item.codeName"
                    :value="item.codeId"
                  />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('stores.Store Name')" prop="storeName">
                <el-input 
                  v-model="formData.storeName" 
                  :placeholder="$t('stores.Store Name')"
                  clearable 
                />
              </el-form-item>
              <el-form-item :label="$t('stores.Customer Store Code')" prop="customerStoreCode">
                <el-input 
                  v-model="formData.customerStoreCode" 
                  :placeholder="$t('stores.Customer Store Code')"
                  clearable 
                />
              </el-form-item>
              <el-form-item :label="$t('stores.Search Key')" prop="searchKey">
                <el-input 
                  v-model="formData.searchKey" 
                  :placeholder="$t('stores.Search Key')"
                  clearable
                />
              </el-form-item>

              <div class="section-tit"><div class="flag"></div>{{$t('stores.other')}}</div>
              <el-form-item :label="$t('stores.Comment')" prop="comment">
                <el-input 
                  v-model="formData.comment" 
                  :placeholder="$t('stores.Comment')"
                  clearable
                />
              </el-form-item>
              <el-form-item :label="$t('stores.Address')" prop="addr">
                <el-input 
                  v-model="formData.addr" 
                  :placeholder="$t('stores.Address')"
                  clearable
                />
              </el-form-item>
              <el-form-item :label="$t('stores.Manger')" prop="manager">
                <el-input 
                  v-model="formData.manager" 
                  :placeholder="$t('stores.Manger')"
                  clearable
                />
              </el-form-item>
              <el-form-item :label="$t('stores.Phone')" prop="phone">
                <el-input 
                  v-model="formData.phone" 
                  :placeholder="$t('stores.Phone')"
                  clearable 
                />
              </el-form-item>

              <!-- 保存按钮 -->
              <!-- <div class="form-btn-section">
                <el-button color="#28C76F" size="small" @click="handleClose" >{{$t('button.Cancel')}}</el-button>
                <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" >{{$t('button.Save')}}</el-button>
              </div> -->

            </el-form>
          </div>
        </el-scrollbar>
      </div>
      <!-- 保存按钮 -->
      <div class="form-btn-section">
        <el-button color="#28C76F" size="small" @click="handleClose" >{{$t('button.Cancel')}}</el-button>
        <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" >{{$t('button.Save')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { trim } from 'lodash';
  import { storeCreate, storeUpdate } from "@/libs/api/system-management-stores";
  let loading;

  export default {
    name:'createCustomer',
    components: {
    },
    props: {
      statusList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        parentStoreName:'', // 
        initFormData:{
          storeId:'',
          storeName:'', //门店名称
          storeState: 102, // 门店状态
          level:'', //	开放层级
          searchKey:'', // 门店快速检索别名
          overTime:'', //门店过期自动删除日志的天数
          customerStoreCode:'',//客户自定义门店code
          comment:'', //门店描述
          phone:'', //门店管理者电话
          manager:'', //门店管理者
          addr:'', // 门店地址
          merchantId:'', //商户号
          isEffect: 0, // 是否有效
        },
        formData:{
          storeId:'',
          storeName:'', //门店名称
          storeState: 102, // 门店状态
          level:'', //	开放层级
          searchKey:'', // 门店快速检索别名
          overTime:'', //门店过期自动删除日志的天数
          customerStoreCode:'',//客户自定义门店code
          comment:'', //门店描述
          phone:'', //门店管理者电话
          manager:'', //门店管理者
          addr:'', // 门店地址
          merchantId:'', //商户号
          isEffect: 0, // 是否有效
        },
        rules:{
          storeName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          customerStoreCode:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
            // { max: Math.pow(10,100)-1, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          searchKey:[
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          comment:[
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          addr:[
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          manager:[
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ],
          phone:[
            { max: 20, message: this.$t('formValidate.maxLength20'), trigger: 'blur'}
          ]
          
        },
      }
    },
    created(){
      
    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.storeId){
          let data ={
            ...val,
            // status:val.status ===1401 ? true : false,
            // customerType:val.translateCustomerType
          }
          this.formData = data
          this.parentStoreName = val.parentStoreName
        } else {
          let obj = {...this.initFormData}
          this.formData = obj
          this.parentStoreName = localStorage.getItem('storeName')
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.storeCreate()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建门店/更新门店
      async storeCreate(){
        let {storeId,storeName,storeState,level,searchKey,overTime,customerStoreCode, comment, phone, manager, addr, merchantId, isEffect} = this.formData
        let params = {
          storeName:trim(storeName),
          storeState,
          level,
          searchKey:trim(searchKey),
          overTime,
          customerStoreCode:trim(customerStoreCode),
          comment,
          phone,
          manager,
          addr, 
          merchantId, 
          isEffect, 
        }
        let res = null
        let message =  this.$t('commonMessage.CreateSucess')
        console.log({storeId})
        try {
          if(storeId){
            params.storeId = storeId
            message = this.$t('commonMessage.ModifySucess')
            res = await storeUpdate(params)
          } else {
            params.parentStoreId = localStorage.getItem('storeId')
            res = await storeCreate(params)
          }
          let response = res.data
          if(response.state){
            this.$message({
              message:message,
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          loading.close()
          this.$message.error(error.message);
        }
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .create-store{
    .form-btn-section{
      padding-top: 20px;
      margin-top: 0;
      border-top: 1px solid #E5E5E5;
    }
  }
</style>