<template>
  <div class="common-table-list common-table-list-stores">  
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link> 
      <span> > {{ $t('System Management')}} > </span>
      <span style="color:#3461FF">{{ $t('Stores') }}</span>
    </div>  
    <div class="hd card">
      <div>
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{$t('button.New')}}
        </el-button>
        <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selection()">
          <i class="el-icon-delete" />
          {{$t('button.Batch Delete')}}
        </el-button>
      </div>
    </div>
    <div class="bd card">
      <!--切换门店 -->
      <div class="table-header">
        <div>
          <span class="text"> {{$t('stores.Records')}}: {{pageParams.total}}</span>
          <el-select v-model="searchParams.storeQueryType" @change="getData" placeholder="" size="small">
            <el-option :label="$t('ThisStore')" value="2" />
            <el-option :label="$t('ZimenStore')" value="1" />
            <el-option :label="$t('AllStore')" value="0" />
          </el-select>
        </div>
      </div>

      <!-- 表格 -->
      <el-table 
        :data="tableData" 
        style="width: 100%"
        table-layout="fixed"
        v-loading="loading"
        ref="normalTable"
        @sort-change="handleSort"
      >
      <el-table-column align="center" type="selection" fixed>
        <el-table-column align="center" width="55" type="selection">
        </el-table-column>
      </el-table-column>

        <!-- <el-table-column width="50" fixed >
          <el-table-column type="selection" width="50">
            <template slot="header" slot-scope="scope"></template>
          </el-table-column>
        </el-table-column> -->

        <el-table-column :label="$t('stores.Store Name')" prop="store_name" width="180" sortable="custom" header-align="center" fixed>
          <el-table-column  prop="storeName" width="180" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.storeName" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('stores.Customer Store Code')" prop="customer_store_code" min-width="190" sortable="custom" header-align="center">
          <el-table-column  prop="customerStoreCode" min-width="190" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.customerStoreCode" @input="getData" size="mini" placeholder="" clearable/>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('stores.Superior Store')" prop="store_name"  min-width="140" sortable="custom" header-align="center">
          <el-table-column  prop="parentStoreName" min-width="140" align="center">
            <template slot="header" slot-scope="scope">
              <el-input v-model="searchParams.parentStoreName" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('stores.Status')" min-width="150" prop="store_state" sortable="custom" header-align="center">
          <el-table-column prop="stateName" min-width="150" align="center">
            <template #header>
              <el-select v-model="searchParams.storeStatus" placeholder="" size="mini"  @change="getData" clearable>
                <el-option
                  v-for="item in statusList"
                  :key="item.codeId"
                  :label="item.codeName"
                  :value="item.codeId"
                />
              </el-select>
            </template>
            <template #default="scope">
              <div :class="`status-${scope.row.storeState} status`">{{scope.row.stateName}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('stores.Search Key')" min-width="120" prop="search_key" sortable="custom" header-align="center">
          <el-table-column  prop="searchKey" min-width="120" align="center">
            <template slot="header"  min-width="120" slot-scope="scope">
              <el-input v-model="searchParams.searchKey" @input="getData" size="mini" placeholder="" clearable />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column :label="$t('stores.Creation Time')" min-width="230" header-align="center">
          <el-table-column prop="createTime" min-width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.createTime"
                type="daterange"
                start-placeholder=""
                end-placeholder=""
                size="mini"
                @change="handleDateChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('stores.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <span class="table-icon"  @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

    </div>

    <!-- 创建用户 -->
    <createStore 
      ref="createStore" 
      :statusList="statusList"
      :editData="editData" 
      @getData="getData">
    </createStore>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="storeDelete"></deleteConfirmDialog>

  </div>
</template>
<script>
  import { getStoreList, getStatusList , storeDelete} from "@/libs/api/system-management-stores";
  import createStore from './components/createStore'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
  import pagination from '@/components/pagination'

  import {commonStore} from '@/store/global-select-store'

  export default {
    name:'storePage',
    components: {
      createStore,
      deleteConfirmDialog,
      pagination
    },
    props: {},
    data() {
      return {
        commonStore,
        tableData:[],
        statusList:[],
        editData:{},
        deleteData:{},
        loading:false,
        searchParams:{
          storeId:'',
          isEffect:0,
          storeQueryType: '0', //门店查询类型 ,0：所有门店，1：子门店，2：当前门店
          storeName:'', //门店名称
          parentStoreName:'',//上级门店名称
          storeStatus:'', //门店状态
          customerStoreCode:'',//	门店自定义编码
          searchKey:'', //门店别称
          startCreateTime:'',//开始 门店创建时间
          endCreateTime:'',//结束 门店创建时间
          createTime:['',''],
          orders:[]//排序字段 , 允许多组，以JSONArray格式保存,['desc:tagIdentity']
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
      }
    },
    created(){
      commonStore.setInjectCallBack(this.getData)
      this.getData()
      this.getStatusList()
    },
    mounted(){
      
    },
    methods: {
      // 获取数据
      async getData(){
        let {isEffect,storeQueryType,storeName,parentStoreName,storeStatus,customerStoreCode,searchKey,createTime,orders} = this.searchParams
        let {current,size} = this.pageParams
        let storeId = localStorage.getItem('storeId');
        console.log(this.searchParams);
        let params = {
          storeId,
          isEffect,
          storeQueryType,
          storeName,
          superiorStoreName:parentStoreName,
          storeStatus,
          customerStoreCode,
          searchKey,
          startCreateTime:createTime[0],
          endCreateTime:createTime[1],
          current,
          size,
          orders
        }
        try {
          const res = await getStoreList(params)
          let response = res.data
          if(response.state){
            let dataList = response.data.records
            this.tableData = dataList
            this.pageParams.total = response.data.total
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 获取状态列表
      async getStatusList(){
        try {
          const res = await getStatusList()
          let response = res.data
          console.log('获取状态',response)
          if(response.state){
            this.statusList = response.data.storeStateList
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      },
      // 表格排序
      handleSort(column, prop, order){
        console.log('表格排序',column, prop, order)
        let arr = []
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择change
      handleDateChange(val){
        console.log('时间选择---',val)
        let searchParams ={
          ...this.searchParams
        }
        if(!val){
          searchParams.createTime = ['','']
        }
        this.searchParams = searchParams
        this.getData()
      },
      // 新增
      handleAdd(){
        this.editData = {}
        this.$refs.createStore.open()
      },
      // 编辑
      handleEdit(row){
        console.log('编辑',row)
        this.editData = row
        this.$refs.createStore.open()
      },
      // 删除  ---批量删除
      handleBatchDel(){
        const selection = this.$refs.normalTable.selection
        let deleteData ={}
        let storeIds = selection.map(it => it.storeId)
        deleteData.storeId = storeIds.join(',')
        this.deleteData = deleteData
        // this.dialogVisible =true
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除  ---单个删除
      handleDelete(row){
        console.log('删除',row)
        this.deleteData = row
        // this.dialogVisible =true
        this.$refs.deleteConfirmDialog.open()
      },
      // 多选复选框---获取选中的数量
      selection() {
        console.log(this.$refs.normalTable && this.$refs.normalTable.selection.length);
        return this.$refs.normalTable && this.$refs.normalTable.selection.length
      },
      // api---删除客户
      async storeDelete(){
        let {storeId} = this.deleteData
        let params = {
          storeIds:storeId
        }
        try {
          const res = await storeDelete(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            this.$refs.deleteConfirmDialog.close()
            this.getData()
          } else{
            this.$refs.deleteConfirmDialog.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
        
      }
    }
  }
</script>
<style lang="scss" scoped>
  .common-table-list-stores ::v-deep {
    .el-table
      .el-table__fixed-header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
      display: none;
    }
  }
</style>