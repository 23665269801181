import router from "../axios";
// import $http, { $post, $get} from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

//获取门店列表
function getStoreList(data) {
  return router({
    url: baseUrl+"store/v1/getList",
    data: data,
    method: "POST",
  });
}

//获取门店状态
function getStatusList(data) {
  return router({
    url: baseUrl+"store/v1/beforeCreate",
    data: data,
    method: "POST",
  });
}

// 创建门店  只有商户可创建一级门店，一级门店用户可创建下级门店
function storeCreate(data) {
  return router({
    url: baseUrl+"store/v1/create",
    data: data,
    method: "POST",
  });
}
//  更新门店, 一级门店层级修改时，会判断是否存在超过待修改门店层级的子门店，如果有则修改失败，overTime修改时，会自动同步到所有子门店
function storeUpdate(data) {
  return router({
    url: baseUrl+"store/v1/update",
    data: data,
    method: "POST",
  });
}

// 物理删除门店, 门店存在下级门店时，不允许删除
function storeDelete(data) {
  return router({
    url: baseUrl+"store/v1/delete",
    data: data,
    method: "POST",
  });
}

export {
  getStoreList,
  getStatusList,
  storeCreate,
  storeUpdate,
  storeDelete
}